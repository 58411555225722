import MenuProvider from "context/MenuProvider";
import ApolloProvider from "context/ApolloProvider";
import React, { FC, useMemo } from "react";
import ModalProvider from "context/ModalProvider";
import useMemoizedChildren from "hooks/useMemoizedChildren";

type Props = { pageProps: { initialApolloState: any } };
const GlobalProvider: FC<Props> = ({ pageProps, children }) => {
  const memoizedChildren = useMemoizedChildren(children);

  return (
    <ApolloProvider initialState={pageProps.initialApolloState}>
      <ModalProvider>
        <MenuProvider>{memoizedChildren}</MenuProvider>
      </ModalProvider>
    </ApolloProvider>
  );
};

export default GlobalProvider;
