import React, { FC } from "react";
import NextLink from "next/link";
import { styled } from "linaria/react";

const LinkComponent: FC<any> = (props) => {
  return (
    <span>
      <NextLink {...props} />
    </span>
  );
};

const Link = styled(LinkComponent)`
  a {
    color: blue;
  }
`;

export default Link;
