import Body from "components/Body";
import Flex from "components/Flex";
import Section from "components/Section";
import GiveCrypto from "icons/GiveCrypto.svg";
import Link from "next/link";
import React, { FC } from "react";
import { breakpoints, colors, FontScale, SpaceScale } from "theme";
import Twitter from "icons/Twitter.svg";
import Medium from "icons/Medium.svg";
import { styled } from "linaria/react";
import { css } from "linaria";

const Footer: FC = () => {
  return (
    <footer>
      <Section background="blue" color="white">
        <Container justify="space-between" align="center">
          <Link href="/">
            <a aria-label="home">
              <GiveCrypto
                width={160}
                color={colors.white}
                secondaryColor={colors.white}
              />
            </a>
          </Link>
          <Content>
            <Body color="white" className={SmallBody}>
              &copy; 2018 - {new Date().getFullYear()} GiveCrypto &middot; All
              rights reserved
            </Body>
          </Content>
          <div>
            <InlineLink
              aria-label="Twitter"
              href="http://twitter.com/givecrypto/"
            >
              <Twitter width={18} height={18} color={colors.white} />
            </InlineLink>
            <InlineLink
              aria-label="Twitter"
              href="http://medium.com/givecrypto/"
            >
              <Medium width={18} height={18} color={colors.white} />
            </InlineLink>{" "}
          </div>
        </Container>
      </Section>
    </footer>
  );
};

const SmallBody = css`
  font-size: ${FontScale(4)};
  padding-bottom: 5px;
  ${breakpoints.mobile} {
    margin-top: 0;
    font-size: ${FontScale(3.75)};
  }
`;

const InlineLink = styled.a`
  display: inline-block;
  margin-right: ${SpaceScale(4.5)};
  &:last-child {
    margin-right: 0;
  }
`;
const Container = styled(Flex)`
  ${breakpoints.mobile} {
    flex-wrap: wrap;
  }
`;

const Content = styled(Flex)`
  opacity: 0.7;
  ${breakpoints.mobile} {
    order: 3;
  }
`;

export default Footer;
