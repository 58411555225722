import { styled } from "linaria/react";
import Flex from "components/Flex";
import React, { FC } from "react";
import { colors } from "theme";

import Icon from "./menu-icon.svg";
import useMenu from "hooks/useMenu";

type Props = any;
const MenuIcon: FC<Props> = () => {
  const { openMenu } = useMenu();

  return (
    <Container>
      <a onClick={() => openMenu()}>
        <Icon width={26} height={26} />
      </a>
    </Container>
  );
};

const Container = styled(Flex)`
  path {
    stroke: ${colors.charcoal};
  }
`;
export default MenuIcon;
