/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignUpAmbassador
// ====================================================

export interface SignUpAmbassador {
  signUpAmbassador: boolean;
}

export interface SignUpAmbassadorVariables {
  input: SignUpAmbassadorInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MyPosts
// ====================================================

export interface MyPosts_session_user_posts_author_activeFundingRequest {
  __typename: "FundingRequest";
  id: string;
}

export interface MyPosts_session_user_posts_author_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to fit within 600px square
   */
  medium: string;
}

export interface MyPosts_session_user_posts_author {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Active funding request (if any) of the User
   */
  activeFundingRequest: MyPosts_session_user_posts_author_activeFundingRequest | null;
  /**
   * Profile image
   */
  image: MyPosts_session_user_posts_author_image | null;
}

export interface MyPosts_session_user_posts_review_author_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to cover a 256px square, and then cropped square
   */
  thumbnail: string;
}

export interface MyPosts_session_user_posts_review_author {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Profile image
   */
  image: MyPosts_session_user_posts_review_author_image | null;
}

export interface MyPosts_session_user_posts_review {
  __typename: "Review";
  createdAt: any;
  author: MyPosts_session_user_posts_review_author;
  decision: ReviewDecision;
  feedback: string | null;
}

export interface MyPosts_session_user_posts_media_Video {
  __typename: "Video";
}

export interface MyPosts_session_user_posts_media_Image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to fit within 600px square
   */
  medium: string;
  /**
   * Width of the original image
   */
  width: number;
  /**
   * Height of the original image
   */
  height: number;
}

export type MyPosts_session_user_posts_media = MyPosts_session_user_posts_media_Video | MyPosts_session_user_posts_media_Image;

export interface MyPosts_session_user_posts {
  __typename: "Post";
  id: string;
  createdAt: any;
  /**
   * Plaintext body. No character limit, no markdown.
   */
  body: string;
  /**
   * User who created the post.
   */
  author: MyPosts_session_user_posts_author;
  /**
   * Ambassador's latest review of this subject, if any
   */
  review: MyPosts_session_user_posts_review | null;
  /**
   * Attached media items
   */
  media: MyPosts_session_user_posts_media[];
}

export interface MyPosts_session_user {
  __typename: "User";
  id: string;
  /**
   * List of Posts authored by this user
   */
  posts: (MyPosts_session_user_posts | null)[];
}

export interface MyPosts_session {
  __typename: "Session";
  user: MyPosts_session_user | null;
}

export interface MyPosts {
  /**
   * Get the current session
   */
  session: MyPosts_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MyReviewTasks
// ====================================================

export interface MyReviewTasks_myReviewTasks_subject_User_review_author_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to cover a 256px square, and then cropped square
   */
  thumbnail: string;
}

export interface MyReviewTasks_myReviewTasks_subject_User_review_author {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Profile image
   */
  image: MyReviewTasks_myReviewTasks_subject_User_review_author_image | null;
}

export interface MyReviewTasks_myReviewTasks_subject_User_review {
  __typename: "Review";
  createdAt: any;
  author: MyReviewTasks_myReviewTasks_subject_User_review_author;
  decision: ReviewDecision;
  feedback: string | null;
}

export interface MyReviewTasks_myReviewTasks_subject_User {
  __typename: "User";
  id: string;
  updatedAt: any;
  /**
   * Ambassador's latest review of this subject, if any
   */
  review: MyReviewTasks_myReviewTasks_subject_User_review | null;
}

export interface MyReviewTasks_myReviewTasks_subject_Post_author_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to cover a 256px square, and then cropped square
   */
  thumbnail: string;
}

export interface MyReviewTasks_myReviewTasks_subject_Post_author {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Profile image
   */
  image: MyReviewTasks_myReviewTasks_subject_Post_author_image | null;
}

export interface MyReviewTasks_myReviewTasks_subject_Post_review_author_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to cover a 256px square, and then cropped square
   */
  thumbnail: string;
}

export interface MyReviewTasks_myReviewTasks_subject_Post_review_author {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Profile image
   */
  image: MyReviewTasks_myReviewTasks_subject_Post_review_author_image | null;
}

export interface MyReviewTasks_myReviewTasks_subject_Post_review {
  __typename: "Review";
  createdAt: any;
  author: MyReviewTasks_myReviewTasks_subject_Post_review_author;
  decision: ReviewDecision;
  feedback: string | null;
}

export interface MyReviewTasks_myReviewTasks_subject_Post {
  __typename: "Post";
  id: string;
  updatedAt: any;
  /**
   * User who created the post.
   */
  author: MyReviewTasks_myReviewTasks_subject_Post_author;
  /**
   * Ambassador's latest review of this subject, if any
   */
  review: MyReviewTasks_myReviewTasks_subject_Post_review | null;
}

export interface MyReviewTasks_myReviewTasks_subject_FundingRequest_author_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to cover a 256px square, and then cropped square
   */
  thumbnail: string;
}

export interface MyReviewTasks_myReviewTasks_subject_FundingRequest_author {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Profile image
   */
  image: MyReviewTasks_myReviewTasks_subject_FundingRequest_author_image | null;
}

export interface MyReviewTasks_myReviewTasks_subject_FundingRequest_review_author_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to cover a 256px square, and then cropped square
   */
  thumbnail: string;
}

export interface MyReviewTasks_myReviewTasks_subject_FundingRequest_review_author {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Profile image
   */
  image: MyReviewTasks_myReviewTasks_subject_FundingRequest_review_author_image | null;
}

export interface MyReviewTasks_myReviewTasks_subject_FundingRequest_review {
  __typename: "Review";
  createdAt: any;
  author: MyReviewTasks_myReviewTasks_subject_FundingRequest_review_author;
  decision: ReviewDecision;
  feedback: string | null;
}

export interface MyReviewTasks_myReviewTasks_subject_FundingRequest {
  __typename: "FundingRequest";
  id: string;
  updatedAt: any;
  /**
   * User who created the request
   */
  author: MyReviewTasks_myReviewTasks_subject_FundingRequest_author;
  /**
   * Ambassador's latest review of this subject, if any
   */
  review: MyReviewTasks_myReviewTasks_subject_FundingRequest_review | null;
}

export type MyReviewTasks_myReviewTasks_subject = MyReviewTasks_myReviewTasks_subject_User | MyReviewTasks_myReviewTasks_subject_Post | MyReviewTasks_myReviewTasks_subject_FundingRequest;

export interface MyReviewTasks_myReviewTasks {
  __typename: "ReviewTask";
  kind: ReviewTaskKind;
  createdAt: any;
  isCompleted: boolean;
  subject: MyReviewTasks_myReviewTasks_subject | null;
}

export interface MyReviewTasks {
  /**
   * Show all review tasks for signed in Ambassador. If `isCompleted` is provided, only tasks with a matching `isCompleted` status are returned.
   */
  myReviewTasks: MyReviewTasks_myReviewTasks[];
}

export interface MyReviewTasksVariables {
  isCompleted?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Session
// ====================================================

export interface Session_session_user_posts_author_activeFundingRequest {
  __typename: "FundingRequest";
  id: string;
}

export interface Session_session_user_posts_author_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to fit within 600px square
   */
  medium: string;
}

export interface Session_session_user_posts_author {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Active funding request (if any) of the User
   */
  activeFundingRequest: Session_session_user_posts_author_activeFundingRequest | null;
  /**
   * Profile image
   */
  image: Session_session_user_posts_author_image | null;
}

export interface Session_session_user_posts_review_author_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to cover a 256px square, and then cropped square
   */
  thumbnail: string;
}

export interface Session_session_user_posts_review_author {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Profile image
   */
  image: Session_session_user_posts_review_author_image | null;
}

export interface Session_session_user_posts_review {
  __typename: "Review";
  createdAt: any;
  author: Session_session_user_posts_review_author;
  decision: ReviewDecision;
  feedback: string | null;
}

export interface Session_session_user_posts_media_Video {
  __typename: "Video";
}

export interface Session_session_user_posts_media_Image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to fit within 600px square
   */
  medium: string;
  /**
   * Width of the original image
   */
  width: number;
  /**
   * Height of the original image
   */
  height: number;
}

export type Session_session_user_posts_media = Session_session_user_posts_media_Video | Session_session_user_posts_media_Image;

export interface Session_session_user_posts {
  __typename: "Post";
  id: string;
  createdAt: any;
  /**
   * Plaintext body. No character limit, no markdown.
   */
  body: string;
  /**
   * User who created the post.
   */
  author: Session_session_user_posts_author;
  /**
   * Ambassador's latest review of this subject, if any
   */
  review: Session_session_user_posts_review | null;
  /**
   * Attached media items
   */
  media: Session_session_user_posts_media[];
}

export interface Session_session_user_cohort_pendingInvitations {
  __typename: "Invitation";
  id: string;
  createdAt: any;
  name: string;
  phone: string;
}

export interface Session_session_user_cohort {
  __typename: "Cohort";
  /**
   * Remaining invitation limit
   * 
   * If value is missing, infinite invitations remain
   */
  remainingInvitationLimit: number | null;
  /**
   * Count of onboarded users
   */
  userCount: number;
  /**
   * List of outstanding invitations
   */
  pendingInvitations: Session_session_user_cohort_pendingInvitations[];
}

export interface Session_session_user_activeFundingRequest_fiatValue {
  __typename: "Value";
  /**
   * Amount denominated in the accompanying `currency`
   */
  amount: number;
}

export interface Session_session_user_activeFundingRequest_review_author_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to cover a 256px square, and then cropped square
   */
  thumbnail: string;
}

export interface Session_session_user_activeFundingRequest_review_author {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Profile image
   */
  image: Session_session_user_activeFundingRequest_review_author_image | null;
}

export interface Session_session_user_activeFundingRequest_review {
  __typename: "Review";
  createdAt: any;
  author: Session_session_user_activeFundingRequest_review_author;
  decision: ReviewDecision;
  feedback: string | null;
}

export interface Session_session_user_activeFundingRequest {
  __typename: "FundingRequest";
  id: string;
  /**
   * Plaintext body. No character limit, no markdown.
   */
  body: string;
  /**
   * List of categories
   */
  categories: string[];
  createdAt: any;
  updatedAt: any;
  /**
   * Fiat value
   */
  fiatValue: Session_session_user_activeFundingRequest_fiatValue;
  /**
   * Ambassador's latest review of this subject, if any
   */
  review: Session_session_user_activeFundingRequest_review | null;
}

export interface Session_session_user_review_author_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to cover a 256px square, and then cropped square
   */
  thumbnail: string;
}

export interface Session_session_user_review_author {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Profile image
   */
  image: Session_session_user_review_author_image | null;
}

export interface Session_session_user_review {
  __typename: "Review";
  createdAt: any;
  author: Session_session_user_review_author;
  decision: ReviewDecision;
  feedback: string | null;
}

export interface Session_session_user_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to fit within 600px square
   */
  medium: string;
  /**
   * Resized to cover a 256px square, and then cropped square
   */
  thumbnail: string;
}

export interface Session_session_user {
  __typename: "User";
  id: string;
  role: UserRole;
  name: string;
  location: string | null;
  bio: string | null;
  /**
   * Wallet Address - Private
   */
  walletAddress: any | null;
  /**
   * Whether this User has accepted the current TOS. May transition back to false if the TOS change.
   */
  hasAcceptedTerms: boolean;
  gender: string | null;
  dateOfBirth: any | null;
  numberOfChildren: string | null;
  /**
   * List of Posts authored by this user
   */
  posts: (Session_session_user_posts | null)[];
  /**
   * Cohort as seen by the authenticated user
   */
  cohort: Session_session_user_cohort | null;
  /**
   * Active funding request (if any) of the User
   */
  activeFundingRequest: Session_session_user_activeFundingRequest | null;
  /**
   * Ambassador's latest review of this subject, if any
   */
  review: Session_session_user_review | null;
  /**
   * Profile image
   */
  image: Session_session_user_image | null;
}

export interface Session_session {
  __typename: "Session";
  id: string;
  token: string | null;
  user: Session_session_user | null;
}

export interface Session {
  /**
   * Get the current session
   */
  session: Session_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Post
// ====================================================

export interface Post_post_author_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to fit within 600px square
   */
  medium: string;
}

export interface Post_post_author {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Profile image
   */
  image: Post_post_author_image | null;
}

export interface Post_post_review_author_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to cover a 256px square, and then cropped square
   */
  thumbnail: string;
}

export interface Post_post_review_author {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Profile image
   */
  image: Post_post_review_author_image | null;
}

export interface Post_post_review {
  __typename: "Review";
  createdAt: any;
  author: Post_post_review_author;
  decision: ReviewDecision;
  feedback: string | null;
}

export interface Post_post_media_Video {
  __typename: "Video";
}

export interface Post_post_media_Image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to fit within 600px square
   */
  medium: string;
  /**
   * Width of the original image
   */
  width: number;
  /**
   * Height of the original image
   */
  height: number;
}

export type Post_post_media = Post_post_media_Video | Post_post_media_Image;

export interface Post_post {
  __typename: "Post";
  id: string;
  /**
   * Plaintext body. No character limit, no markdown.
   */
  body: string;
  createdAt: any;
  /**
   * User who created the post.
   */
  author: Post_post_author;
  /**
   * Ambassador's latest review of this subject, if any
   */
  review: Post_post_review | null;
  /**
   * Attached media items
   */
  media: Post_post_media[];
}

export interface Post {
  /**
   * Any post
   */
  post: Post_post | null;
}

export interface PostVariables {
  postId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostFields
// ====================================================

export interface PostFields_author_activeFundingRequest {
  __typename: "FundingRequest";
  id: string;
}

export interface PostFields_author_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to fit within 600px square
   */
  medium: string;
}

export interface PostFields_author {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Active funding request (if any) of the User
   */
  activeFundingRequest: PostFields_author_activeFundingRequest | null;
  /**
   * Profile image
   */
  image: PostFields_author_image | null;
}

export interface PostFields_review_author_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to cover a 256px square, and then cropped square
   */
  thumbnail: string;
}

export interface PostFields_review_author {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Profile image
   */
  image: PostFields_review_author_image | null;
}

export interface PostFields_review {
  __typename: "Review";
  createdAt: any;
  author: PostFields_review_author;
  decision: ReviewDecision;
  feedback: string | null;
}

export interface PostFields_media_Video {
  __typename: "Video";
}

export interface PostFields_media_Image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to fit within 600px square
   */
  medium: string;
  /**
   * Width of the original image
   */
  width: number;
  /**
   * Height of the original image
   */
  height: number;
}

export type PostFields_media = PostFields_media_Video | PostFields_media_Image;

export interface PostFields {
  __typename: "Post";
  id: string;
  createdAt: any;
  /**
   * Plaintext body. No character limit, no markdown.
   */
  body: string;
  /**
   * User who created the post.
   */
  author: PostFields_author;
  /**
   * Ambassador's latest review of this subject, if any
   */
  review: PostFields_review | null;
  /**
   * Attached media items
   */
  media: PostFields_media[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: questionFields
// ====================================================

export interface questionFields_asker_image {
  __typename: "Image";
  /**
   * Resized to fit within 600px square
   */
  medium: string;
}

export interface questionFields_asker {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Profile image
   */
  image: questionFields_asker_image | null;
}

export interface questionFields {
  __typename: "Question";
  id: string;
  createdAt: any;
  answeredAt: any | null;
  /**
   * User who created the Question
   */
  asker: questionFields_asker;
  /**
   * Plaintext body of question
   */
  question: string;
  /**
   * Plaintext body of answer
   */
  answer: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: reviewFields
// ====================================================

export interface reviewFields_author_image {
  __typename: "Image";
  /**
   * ID
   */
  id: string;
  /**
   * Resized to cover a 256px square, and then cropped square
   */
  thumbnail: string;
}

export interface reviewFields_author {
  __typename: "User";
  id: string;
  name: string;
  /**
   * Profile image
   */
  image: reviewFields_author_image | null;
}

export interface reviewFields {
  __typename: "Review";
  createdAt: any;
  author: reviewFields_author;
  decision: ReviewDecision;
  feedback: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Ambassador's review decision regarding any reviewable target
 */
export enum ReviewDecision {
  ACCEPTED = "ACCEPTED",
  BANNED = "BANNED",
  REJECTED = "REJECTED",
}

export enum ReviewTaskKind {
  FUNDING_REQUEST = "FUNDING_REQUEST",
  POST = "POST",
  USER = "USER",
}

/**
 * User's role within the system
 */
export enum UserRole {
  ADMIN = "ADMIN",
  AMBASSADOR = "AMBASSADOR",
  RECIPIENT = "RECIPIENT",
}

export interface SignUpAmbassadorInput {
  name: string;
  phone: string;
  location: any;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
