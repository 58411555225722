import { useRouter } from "next/router";
import { useCallback, useContext, useEffect } from "react";
import {MenuContext} from "context/MenuProvider";

const useMenu = () => {
  const { isOpen, setMenuState } = useContext(MenuContext);
  const router = useRouter();

  // Exported callback methods
  const openMenu = useCallback(() => setMenuState(true), []);
  const closeMenu = useCallback(() => setMenuState(false), []);
  const toggleMenu = useCallback(() => setMenuState(!isOpen), [isOpen]);

  useEffect(() => {
    router.events.on("routeChangeComplete", closeMenu);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeComplete", closeMenu);
    };
  }, []);

  return { isOpen, openMenu, closeMenu, toggleMenu };
};

export default useMenu;
