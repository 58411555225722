import useMemoizedChildren from "hooks/useMemoizedChildren";
import { noop } from "lodash";
import { createContext, useState } from "react";

export const MenuContext = createContext<any>({
  isOpen: false,
  setMenuState: noop,
});

const MenuProvider = ({ children }) => {
  const [isOpen, setMenuState] = useState(false);
  const memoizedChildren = useMemoizedChildren(children);

  return (
    <MenuContext.Provider value={{ isOpen, setMenuState }}>
      {memoizedChildren}
    </MenuContext.Provider>
  );
};

export default MenuProvider;
