import { useRouter } from "next/router";
import { useEffect } from "react";
import GoogleAnalytics, { EventArgs, TrackerNames } from "react-ga";
import { GOOGLE_TRACKING_ID } from "src/config";

if (GOOGLE_TRACKING_ID) {
  // Init google analytics
  GoogleAnalytics.initialize(GOOGLE_TRACKING_ID);
}

export const trackEvent = (args: EventArgs, trackerNames?: TrackerNames) => {
  GoogleAnalytics.event(args, trackerNames);
};

const trackPage = ({ page }: { page: string }) => {
  GoogleAnalytics.set({ page });
  GoogleAnalytics.pageview(page);
};

const useAnalytics = () => {
  const { pathname } = useRouter();

  useEffect(() => {
    const page = pathname;

    trackPage({ page });
  }, [pathname]);
};

export default useAnalytics;
