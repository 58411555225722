import Flex from "components/Flex";
import React, { Component } from "react";
import { logError } from "utils/errors";

type Props = any; // TODO
type State = { error: Error | null };
export default class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: null,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    logError({ error, errorInfo });

    this.setState({ error });
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  public render() {
    const { error } = this.state;
    if (error) {
      return (
        <Flex align="center" justify="center">
          <h2>Error</h2>
          <p data-testid="error-message">{error.message}</p>
        </Flex>
      );
    } else {
      return this.props.children;
    }
  }
}
