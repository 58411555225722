import Layout from "components/Layout/Layout";
import GlobalProvider from "context/GlobalProvider";
import React from "react";
import { MOCK_API } from "src/config";
import Analytics from "components/Analytics";

// TODO Stop importing this when global linaria css support is working
import "src/styles/globals.css";
import Head from "next/head";

const App = ({ Component, pageProps }) => {
  return (
    <>
      <Head>
        <script
          type="text/javascript"
          src="https://commerce.coinbase.com/v1/checkout.js?version=201807"
        />
      </Head>
      <Analytics>
        <GlobalProvider pageProps={pageProps}>
          <Layout {...pageProps}>
            <Component {...pageProps} />
          </Layout>
        </GlobalProvider>
      </Analytics>
    </>
  );
};

if (MOCK_API === "true") {
  require("../mocks");
}

export default App;
