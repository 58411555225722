import useMemoizedChildren from "hooks/useMemoizedChildren";
import React, { FC } from "react";
import { GOOGLE_TRACKING_ID } from "src/config";
import useAnalytics from "./useAnalytics";

const Analytics: FC = ({ children }) => {
  const memoizedChildren = useMemoizedChildren(children);
  if (GOOGLE_TRACKING_ID) useAnalytics();

  return <>{memoizedChildren}</>;
};

export default Analytics;
