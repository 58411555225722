import { useRef, useEffect } from "react";
import { logError } from "utils/errors";

const useEscapeListener = (handler) => {
  try {
    // Make sure element supports addEventListener
    const isSupported = window && window.addEventListener;
    if (!isSupported) return;

    // Create a ref that stores handler
    const savedHandler = useRef<any>();

    useEffect(() => {
      savedHandler.current = handler;
    }, [handler]);

    useEffect(
      () => {
        // Make sure element supports addEventListener
        const isSupported = window && window.addEventListener;
        if (!isSupported) return;

        // Create event listener that calls handler function stored in ref
        const escapeListener = (event) => {
          if (["Escape", "Esc"].includes(event.key)) {
            savedHandler.current(event);
          }
        };

        // Add event listener
        window.addEventListener("keyup", escapeListener);

        // Remove event listener on cleanup
        return () => {
          window.removeEventListener("keyup", escapeListener);
        };
      },
      [window] // Re-run if eventName or element changes
    );
  } catch (e) {
    logError(e);
  }
};

export default useEscapeListener;
