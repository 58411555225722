import React, { FC } from "react";
import Link from "components/Link";
import { useRouter } from "next/router";
import { colors } from "theme";
import { styled } from "linaria/react";
import useMemoizedChildren from "hooks/useMemoizedChildren";

// TODO cleanup this component
const NavLink: FC<any> = ({ href = "/", children, ...rest }) => {
  const memoizedChildren = useMemoizedChildren(children);
  const { pathname } = useRouter();
  const isActive = pathname === href;

  return (
    <StyledContainer isActive={isActive}>
      <Link {...rest} href={href}>
        {memoizedChildren}
      </Link>
    </StyledContainer>
  );
};

const StyledContainer = styled.span<{ isActive: boolean }>`
  > a {
    color: ${({ isActive }) =>
      isActive ? colors.apple : colors.olympicSkaterSpandex} !important;

    &:active,
    &:hover {
      color: ${colors.apple} !important;
    }
  }
`;

export default NavLink;
