import Flex from "components/Flex";
import Heading from "components/Heading";
import NavLink from "components/NavLink";
import React, { FC } from "react";
import { colors, FontScale, SpaceScale } from "theme";

import MenuPane from "./MenuPane";
import { GiveTrigger } from "components/Give";
import { styled } from "linaria/react";
import { SignupModalTrigger } from "views/ambassadors/Signup/components/SignupModal";
import ButtonGroup from "components/Button/ButtonGroup";

type Props = any;
const Menu: FC<Props> = () => {
  return (
    <MenuPane id="menu">
      <nav aria-label="Sidebar navigation">
        <Flex column>
          <Heading level={3} style={menuItemStyles} color="eggshell">
            <NavLink href="/">Home</NavLink>
          </Heading>
          <Heading level={3} style={menuItemStyles} color="eggshell">
            <NavLink href="/donors">Donors</NavLink>
          </Heading>
          <Heading level={3} style={menuItemStyles} color="eggshell">
            <NavLink href="/faq">FAQs</NavLink>
          </Heading>
          <Heading level={3} style={menuItemStyles} color="eggshell">
            <NavLink href="https://medium.com/givecrypto">Blog</NavLink>
          </Heading>
          <Divider />
          <GiveTrigger kind="coinbase" />
          {/* <ButtonGroup $align="flex-start">
            <GiveTrigger kind="coinbase" />
            <SignupModalTrigger inverted size="medium">
              Sign up
            </SignupModalTrigger>
          </ButtonGroup> */}
        </Flex>
      </nav>
    </MenuPane>
  );
};

const menuItemStyles = {
  fontSize: FontScale(4.5),
  marginBottom: SpaceScale(5),
  cursor: "pointer",
};
const Divider = styled.div`
  border-radius: 2px;
  height: 4px;
  background: ${colors.oldCouch};
  margin: ${SpaceScale(5)} 0;
  width: ${SpaceScale(5)};
  cursor: pointer;
`;

export default Menu;
