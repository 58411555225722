// import * as Sentry from "@sentry/nextjs";
import { NODE_ENV } from "src/config";

export const logError = ({ error, errorInfo = null }) => {
  // Log errors in dev
  if (NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    return console.error(error);
  }

  // // Tell us more if we can
  // if (errorInfo) {
  //   Sentry.configureScope((scope) => {
  //     Object.keys(errorInfo).forEach((key) => {
  //       scope.setExtra(key, errorInfo[key]);
  //     });
  //   });
  // }

  // // Capture the event
  // Sentry.captureException(error);
};
