import { styled } from "linaria/react";
import React, { FC } from "react";
import { animated, useSpring, config } from "react-spring";
import { transition, colors, depth, SpaceScale } from "theme";

import useMenu from "hooks/useMenu";
import useMemoizedChildren from "hooks/useMemoizedChildren";

type Props = any;
const MenuPane: FC<Props> = ({ children, ...rest }) => {
  const { isOpen, closeMenu } = useMenu();
  const memoizedChildren = useMemoizedChildren(children);
  const paneStyles = useSpring({
    x: isOpen ? "0%" : "100%",
    config: transition.spring,
  });
  const backdropStyles = useSpring({
    opacity: isOpen ? 1 : 0,
    pointerEvents: isOpen ? "all" : "none",
  });

  return (
    <>
      <Container {...rest} style={paneStyles}>
        {memoizedChildren}
      </Container>
      <Backdrop onClick={closeMenu} style={backdropStyles} />
    </>
  );
};

const OVERFLOW = SpaceScale(4);
const PADDING = SpaceScale(5.5);
const Container = styled(animated.div as any)`
  position: fixed;
  z-index: ${depth.top - 1};
  top: 0;
  bottom: 0;
  right: -${OVERFLOW};
  padding: ${PADDING};
  padding-right: calc(${PADDING} + ${OVERFLOW});

  width: 320px;
  background: ${colors.paper};
  box-shadow: 0px 3px 37px rgba(0, 0, 0, 0.21);
`;

const Backdrop = styled(animated.div as any)`
  z-index: ${depth.top - 2};
  background: ${colors.rainCloud};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

export default MenuPane;
