import NavLink from "components/NavLink";
import Link from "components/Link";
import GiveCrypto from "icons/GiveCrypto.svg";
import React, { FC } from "react";
import Headroom from "react-headroom";
import Flex from "components/Flex";
import { styled } from "linaria/react";
import { breakpoints, colors, depth, SpaceScale } from "theme";
import { GiveTrigger } from "components/Give";
import MenuIcon from "components/Menu/MenuIcon";
import { SignupModalTrigger } from "views/ambassadors/Signup/components/SignupModal";
import { css } from "linaria";

const Header: FC = () => {
  return (
    <StyledHeader>
      <Headroom>
        <Container>
          <Inner align="center" justify="space-between">
            <Link href="/">
              <a aria-label="home">
                <GiveCrypto width={160} />
              </a>
            </Link>
            <nav>
              <NavList>
                <li>
                  <GiveTrigger kind="coinbase" as="a">
                    Give now
                  </GiveTrigger>
                </li>
                <li>
                  <NavLink href="/donors">Donors</NavLink>
                </li>
                <li>
                  <NavLink href="/faq">FAQ</NavLink>
                </li>
                <li>
                  <NavLink href="https://medium.com/givecrypto">Blog</NavLink>
                </li>
                {/* <li>
                  <SignupModalTrigger unstyled>
                    <a
                      href="ambassadors/signup"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      Sign up
                    </a>
                  </SignupModalTrigger>
                </li> */}
              </NavList>
            </nav>
            <MenuIconContainer>
              <MenuIcon />
            </MenuIconContainer>
          </Inner>
          <Backdrop />
        </Container>
        <Backdrop />
      </Headroom>
    </StyledHeader>
  );
};

const NavList = styled.ul`
  li {
    display: inline-block;
    list-style: none;
    margin-left: ${SpaceScale(5)};
  }
  a {
    transition: color 200ms;
    color: ${colors.olympicSkaterSpandex};

    &:hover {
      color: ${colors.ivy};
    }
  }
`;
const MenuIconContainer = styled.div`
  height: 32px;
  width: 32px;
  display: none;
  ${breakpoints.mobile} {
    display: block;
  }
`;
const Inner = styled(Flex)`
  position: relative;
  padding: ${SpaceScale(5)};
  z-index: 3;

  ${breakpoints.mobile} {
    nav {
      display: none;
    }
  }
`;
const Container = styled.div`
  position: relative;
  z-index: 2;
  backdrop-filter: blur(6px);
`;
const StyledHeader = styled.header`
  position: relative;
  z-index: ${depth.elevated};
`;
const Backdrop = styled.div`
  background-color: ${colors.white};
  position: absolute;
  opacity: 0.6;
  backdrop-filter: blur(3px);
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
`;

export default Header;
