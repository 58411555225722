const mounted = [];

/* eslint-disable @typescript-eslint/no-var-requires */
if (typeof window === "undefined" && !mounted.includes("SERVER")) {
  mounted.push("SERVER");
  const { server } = require("./server");
  server.listen();
} else if (!mounted.includes("BROWSER")) {
  mounted.push("BROWSER");
  const { worker } = require("./browser");
  worker.start();
}

// Stupid
export {};
