import Squircle from "components/Squircle";
import useMemoizedChildren from "hooks/useMemoizedChildren";
import useWindowSize from "hooks/useWindowSize";
import { styled } from "linaria/react";
import React, { FC } from "react";
import { shadows, breakpoints, colors, depth } from "theme";

type ModalProps = { isOpen: boolean };

export const Empty = styled.span`
  display: none;
`;

const StyledHandler = styled.div<ModalProps>`
  transition: all 300ms ease;
  touch-action: none;
  opacity: 0;

  ${breakpoints.mobile} {
    z-index: ${depth.cover};
    position: absolute;
    top: 1rem;
    right: 1rem;
    text-align: right;
    display: block;
    touch-action: ${({ isOpen }) => (isOpen ? "all" : "none")};
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    transform: ${({ isOpen }) =>
      isOpen ? "translateY(0)" : "translateY(60px)"};
  }
`;
export const ModalCloseHandler: FC<ModalProps & { onClick: () => void }> = ({
  isOpen,
  onClick,
}) => {
  return (
    <StyledHandler
      isOpen={isOpen}
      onClick={() => {
        onClick();
      }}
    >
      X
    </StyledHandler>
  );
};

const ModalSquircle = styled(Squircle)<ModalProps>`
  background: ${colors.white};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  min-width: 550px;
  border-radius: 4px;
  padding: 3rem 2rem;
  transition: transform 300ms ease;
  transform: ${({ isOpen }) => (isOpen ? "translateY(0)" : "translateY(30px)")};
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;

  ${breakpoints.mobile} {
    width: 100%;
    max-width: 100%;
    height: 100vh;
  }
`;

const ModalCtx = styled.div<ModalProps>`
  background: ${colors.white};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  border-radius: 4px;
  z-index: ${depth.high};
  padding: 3rem 2rem;
  transition: all 300ms ease;
  transform: ${({ isOpen }) => (isOpen ? "translateY(0)" : "translateY(30px)")};
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;

  ${breakpoints.mobile} {
    width: 100%;
    height: 100vh;
  }
`;

const ShadowContainer = styled.div`
  filter: drop-shadow(0px 3px 16px rgba(42, 113, 208, 0.23));
  z-index: ${depth.high};
  position: relative;
`;

export const ModalInner: FC<ModalProps> = ({ isOpen, children }) => {
  const memoizedChildren = useMemoizedChildren(children);
  const { width } = useWindowSize();

  if (width >= breakpoints.widths.mobile) {
    return (
      <ShadowContainer>
        <ModalSquircle nature="default" isOpen={isOpen}>
          {memoizedChildren}
        </ModalSquircle>
      </ShadowContainer>
    );
  } else {
    return <ModalCtx isOpen={isOpen}>{memoizedChildren}</ModalCtx>;
  }
};

export const ModalContainer = styled.div<ModalProps>`
  pointer-events: ${({ isOpen }) => (isOpen ? "all" : "none")};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: ${depth.elevated};
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const ModalBackground = styled.div<ModalProps>`
  pointer-events: ${({ isOpen }) => (isOpen ? "all" : "none")};
  opacity: ${({ isOpen }) => (isOpen ? 0.3 : 0)};
  transition: all 400ms;
  background: #000000;
  position: absolute;
  z-index: ${depth.normal};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
