import {
  Empty,
  ModalBackground,
  ModalCloseHandler,
  ModalContainer,
  ModalInner,
} from "components/Modal";
import useDisableScroll from "hooks/useDisableScroll";
import useEscapeListener from "hooks/useEscapeListener";
import useMemoizedChildren from "hooks/useMemoizedChildren";
import { useRouter } from "next/router";
import React, { createContext, memo, ReactNode, useEffect } from "react";
import delay from "utils/delay";

export const ModalContext = createContext<{
  isOpen: boolean;
  openModal: (modal: ReactNode) => void;
  closeModal: () => void;
} | null>(null);

const ModalProvider = ({ children, ...rest }) => {
  // The modal store
  const [isOpen, setIsOpen] = React.useState(false);
  const [ModalContent, setModalContent] = React.useState<ReactNode>(<Empty />);
  const [blockScroll, allowScroll] = useDisableScroll();
  const router = useRouter();

  // Memoize the children to ensure we don't break React.memo
  const memoizedChildren = useMemoizedChildren(children);

  // Listen to the escape button
  useEscapeListener(() => {
    setIsOpen(false);
  });

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = (modal: ReactNode) => {
    setIsOpen(true);
    setModalContent(modal);
  };

  const toggleScrollAbility = async () => {
    if (isOpen) {
      blockScroll();
    } else {
      await delay(500);
      allowScroll();
    }
  };

  useEffect(() => {
    toggleScrollAbility();

    // return () => allowScroll();
  }, [isOpen]);

  useEffect(() => {
    router.events.on("routeChangeStart", closeModal);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", closeModal);
    };
  }, []);

  // Ensure we only re-render the tree iOpen changes
  const value = React.useMemo(() => ({ isOpen, openModal, closeModal }), [
    isOpen,
  ]);

  return (
    <ModalContext.Provider {...rest} value={value}>
      {memoizedChildren}
      <ModalContainer isOpen={isOpen}>
        <ModalInner isOpen={isOpen}>{ModalContent}</ModalInner>
        <ModalCloseHandler isOpen={isOpen} onClick={closeModal} />
        <ModalBackground isOpen={isOpen} onClick={closeModal} />
      </ModalContainer>
    </ModalContext.Provider>
  );
};

export default memo(ModalProvider);
