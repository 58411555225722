import Head from "next/head";
import React, { FC } from "react";
import { colors } from "theme";
import ErrorBoundary from "../ErrorBoundary";
import Header from "components/Header";
import Footer from "components/Footer";
import Menu from "components/Menu";
import useMemoizedChildren from "hooks/useMemoizedChildren";

const Layout: FC = ({ children }) => {
  const memoizedChildren = useMemoizedChildren(children);

  return (
    <>
      <Head>
        <title>GiveCrypto.org</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />

        <link
          href="/icons/icon-16x16.png"
          rel="icon"
          type="image/png"
          sizes="16x16"
        />
        <link
          href="/icons/icon-32x32.png"
          rel="icon"
          type="image/png"
          sizes="32x32"
        />
        <link rel="apple-touch-icon" href="/icons/xxxhdpi/icon.png" />
        {/* Mapbox */}
        <link
          href="https://api.tiles.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.css"
          rel="stylesheet"
        />
        <meta name="theme-color" content={colors.ivy} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>

      <ErrorBoundary>
        <Header />
        {memoizedChildren}
        <Footer />
        <Menu />
      </ErrorBoundary>
    </>
  );
};

export default Layout;
